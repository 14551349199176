import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Vimal Tarsariya",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/vimal-tarsariya.png',
    tag: `vimal-tarsariya`,
    phones: ['(+91) 8469401090'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['vimal@vasundhara.io'],
    linkedin: 'https://in.linkedin.com/in/vimal-tarsariya-8b9456121',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    description: [
        `A successful project manager at Vasundhara Infotech whose leadership skills help in the company's growth.`,
        `He has a goal to create interactive websites with user-friendly UI or visually appealing interfaces.`,
        `His great understanding of business creates a positive impact on company projects.`,
        `Keep informing the project's progress to his team members and encourage them always for learning new things.`,
        `Communication with clients and team members at every stage allows him to complete projects on time or within the deadline.`,
        `His continuous engagement with his team members helps to ease anxiety around uncertainty.`,
        `He aligns with changing strategic objectives through effective and transparent communication.`,
        `He assists his team with the Common Gateway Interface (CGI) and checks the code for website performance optimization.`,
        `He keeps track of the web development process regularly and assists the team in troubleshooting issues they find during the development or testing.`,
        `His ability to execute strong multi-functional teams helps him achieve the customer's objectives.`,
    ]
}

function VimalTarsariya() {
    const [portFolio, setPortFolio] = useState([]);

    useEffect(() => {
        allApiData.aosinit();

        allApiData.allPortfolios(1, 'Website Development', 'app_type').then((data) => {
            setPortFolio(data.data);
        });
    }, [])

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={false}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        typeWise='app_type'
                        isPagination={false}
                        portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default VimalTarsariya